import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import { graphql, navigate } from 'gatsby';

import WallcoveringsCollection from '../components/Wallcoverings/WallcoveringsCollection';
import Seo from '../components/Seo';
import deNodify from '../lib/deNodify';

const WallcoveringsCollectionPage = ({
  data: { collection, patterns, site },
  pageContext: { collectionSlug, noIndex },
  location,
}) => {
  useEffect(() => {
    if (!collection) {
      navigate('/');
    }
  }, [collection]);
  if (!collection) return null;

  const seoTitle = collection?.seo?.title || collection?.title;
  const seoDescription =
    collection?.seo?.description || collection?.description;

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        canonical={`${site.siteMetadata.siteUrl}${location.pathname}`}
        image={collection?.image?.originalSrc}
        titleTemplate="%s | Backdrop x Schumacher"
        noIndex={noIndex}
      />
      <Router basepath={`/collections/${collectionSlug}`}>
        <WallcoveringsCollection
          path="*activeHandle"
          collection={collection}
          patterns={deNodify(patterns)}
          routeBase={`/collections/${collectionSlug}`}
        />
      </Router>
    </>
  );
};

WallcoveringsCollectionPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object,
};

export default WallcoveringsCollectionPage;

export const query = graphql`
  query WallcoveringsCollectionPage($collectionSlug: String!) {
    collection: shopifyCollection(handle: { eq: $collectionSlug }) {
      title
      description
      descriptionHtml
      seo {
        description
        title
      }
      image {
        originalSrc
      }
      products {
        id
        shopifyId
        title
        tags
        handle
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        productType
        designer: metafield(key: "name", namespace: "designer") {
          value
        }
        media {
          ... on ShopifyMediaImage {
            image {
              originalSrc
            }
          }
        }
        featuredImage {
          originalSrc
        }
        description
        variants {
          id
          shopifyId
          title
        }
      }
      shopifyId
    }
    patterns: allShopifyCollection(
      filter: {
        metafields: {
          elemMatch: { key: { eq: "type" }, value: { eq: "Wallcoverings" } }
        }
      }
      sort: { order: ASC, fields: [handle] }
    ) {
      edges {
        node {
          id
          title
          description
          products {
            shopifyId
            id
          }
          shopifyId
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
