import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, ResponsiveContext, ThemeContext } from 'grommet';

import WallcoveringsPatternGridItem from './WallcoveringsPatternGridItem';
import MarkdownContent from '../MarkdownContent';
import useMobile from '../useMobile';
import CollectionProductCount from '../Collection/CollectionProductCount';
import ResponsiveGrid from '../ResponsiveGrid';

const WallcoveringsCollection = ({
  children,
  activeHandle,
  collection,
  patterns,
  routeBase,
}) => {
  const { products } = collection;

  const activeItem = products.find((x) => x.handle === activeHandle);
  const activeIndex = products.indexOf(activeItem);
  const size = useContext(ResponsiveContext);
  const isMobile = useMobile();
  const theme = useContext(ThemeContext);

  const colCount =
    size === 'small' ? 1 : size === 'medsmall' ? 2 : size === 'medium' ? 3 : 3;

  const imageSizes = `(min-width: ${
    theme.global.breakpoints.medium.value
  }px) calc(${100 / 3}vw - ${theme.global.edgeSize.large} - ${
    theme.global.edgeSize.large
  }), (min-width: ${theme.global.breakpoints.medsmall.value}px) calc(33vw - ${
    theme.global.edgeSize.large
  } - ${theme.global.edgeSize.large}),
    (min-width: ${theme.global.breakpoints.small.value}px) calc(50vw - ${
    theme.global.edgeSize.large
  } - ${theme.global.edgeSize.large}), 100vw`;

  return (
    <Box>
      {(collection.title || collection.descriptionHtml) && (
        <Box
          pad={{ horizontal: 'xlarge', vertical: 'medium', top: 'large' }}
          style={{ zIndex: 1 }}
          margin={{ top: 'xlarge' }}
        >
          {collection.title && (
            <Heading style={{ maxWidth: '11em', marginTop: 0 }} level={1}>
              {collection.title}
            </Heading>
          )}
          {collection.descriptionHtml && (
            <MarkdownContent>{collection.descriptionHtml}</MarkdownContent>
          )}
        </Box>
      )}
      <ResponsiveGrid
        disableMobile={true}
        minRowHeight="48vh"
        activeIndex={activeIndex}
        gap={isMobile ? '8vw' : '40px'}
        fill="horizontal"
        columns={{ count: colCount, size: 'auto' }}
        mobileColumns={{ count: 2, size: 'auto' }}
        pad={'xlarge'}
      >
        {products.map((x) => {
          const pattern = patterns.find((z) => {
            return z.products.map((p) => p.shopifyId).indexOf(x.shopifyId) > -1;
          });
          const altProductIds =
            pattern?.products?.map((p) => p.shopifyId) || [];
          const altProducts = products.filter(
            (p) => altProductIds.indexOf(p.shopifyId) > -1
          );
          return (
            <Box width="100%" key={x.shopifyId} height={{ min: '33vw' }}>
              <WallcoveringsPatternGridItem
                key={x.shopifyId}
                altProducts={altProducts}
                pattern={pattern}
                product={x}
                routeBase={routeBase}
                imageSizes={imageSizes}
              />
            </Box>
          );
        })}
      </ResponsiveGrid>
      {children}
      <CollectionProductCount count={collection.products.length} />
    </Box>
  );
};

WallcoveringsCollection.propTypes = {
  path: PropTypes.string,
  children: PropTypes.number,
  activeHandle: PropTypes.string,
  collection: PropTypes.object,
  patterns: PropTypes.array,
  routeBase: PropTypes.string.isRequired,
};

export default memo(WallcoveringsCollection);
