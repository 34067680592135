import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Text } from 'grommet';
import styled from 'styled-components';

import numberToWords from '../../lib/numberToWords';

import SmartLink from '../SmartLink';
import ImgixImage from '../ImgixImage';
import { findImage } from '../../lib/product';

const StyledSmartLink = styled(SmartLink)`
  display: block;
  height: 100%;
  width: 100%;
`;

const NoUnderlineLink = styled(SmartLink)`
  text-decoration: none;
`;

const PatternDisplay = ({
  activeProduct,
  altProducts = [],
  pattern,
  product,
  imageSizes,
}) => {
  const colorwayCountWords = numberToWords(altProducts.length);
  const colorwayCountWordsCased = `${colorwayCountWords
    .substr(0, 1)
    .toUpperCase()}${colorwayCountWords.substr(1)}`;
  const images = activeProduct.media.map(({ image }) => image);

  const image = useMemo(() => {
    return findImage(images, '_grid') || findImage(images, '_slide3');
  }, [activeProduct]);

  return (
    <Box align="start" justify="center">
      <Box>
        <StyledSmartLink to={`/products/${activeProduct.handle}`}>
          <ImgixImage
            src={image}
            fit="contain"
            srcSetOptions={{ auto: ['format', 'compress'], cs: 'srgb', q: 80 }}
            sizes={imageSizes}
            lazy={true}
            width="100%"
          />
        </StyledSmartLink>
      </Box>
      <Box
        margin={{ top: 'small' }}
        style={{ zIndex: altProducts.length + 1 }}
        gap="xsmall"
        fill="horizontal"
      >
        <NoUnderlineLink to={`/products/${activeProduct.handle}`}>
          <Heading level={5}>{pattern ? pattern.title : product.title}</Heading>
        </NoUnderlineLink>
        {product.designer?.value && (
          <Text margin={{ bottom: 'xsmall' }} size="small">
            by {product.designer.value}
          </Text>
        )}
        {altProducts.length > 0 && (
          <Text size="small" color="dark-4">
            {colorwayCountWordsCased} colorways
          </Text>
        )}
      </Box>
    </Box>
  );
};

PatternDisplay.propTypes = {
  activeProduct: PropTypes.object,
  altProducts: PropTypes.array,
  pattern: PropTypes.object,
  product: PropTypes.object,
  imageSizes: PropTypes.string,
};

export default PatternDisplay;
